import Container from "@/components/Containers/Container";
import { usePage } from "@/context/PageContext";
import { useUser } from "@/context/UserContext";
import showAbTestComponent from "@/lib/abtest/showAbTestComponent";
import { useGrowthBook } from "@growthbook/growthbook-react";
import cn from "classnames";
import { differenceInDays, addDays } from "date-fns";
import dynamic from "next/dynamic";
import { memo, useState, useRef, Fragment, useEffect } from "react";

function FlexibleSections({
  data,
  placeholders = undefined,
  cards = undefined,
  faqs = undefined,
  globalContent = undefined,
  liveEvents = undefined,
  plans = undefined,
  podcastTestimonials = undefined,
  featuredImage = undefined,
  recentlyViewed = undefined,
  recentPodcasts = undefined,
  showreel = undefined,
  testimonials = undefined,
  carouselFadeColor = undefined,
  topicCards = undefined,
  circlePostData = undefined,
  book = undefined,
  personalizedCarouselContent = undefined,
  personalizedCirclePostData = undefined,
}) {
  let postData;
  const growthbook = useGrowthBook();
  const { user, userIsNew, userChildren } = useUser();

  const { sectionCount } = usePage();
  let activeSectionCount = sectionCount;

  const [subscriptionStartDate, setSubscriptionStartDate] = useState(
    user?.subscription?.created_at
      ? new Date(user.subscription.created_at)
      : null
  );

  const SubscriptionAgeDebugger = () => {
    const subAgeInput = useRef();
    const saveStartDate = (value) => {
      setSubscriptionStartDate(addDays(new Date(), -value));
    };
    const subAge = subscriptionStartDate
      ? differenceInDays(new Date(), subscriptionStartDate)
      : 0;
    return (
      <div
        className="flex content-center mb-10 leading-10"
        key="growthbook-debugger"
      >
        User Subscription Age: {subAge} days
        <input type="text" ref={subAgeInput} className="!w-24 ml-3 mr-3" />
        <button
          className="p-3 btn btn-primary"
          onClick={(e) => saveStartDate(subAgeInput.current.value)}
        >
          Save
        </button>
      </div>
    );
  };

  let forcerSectionList = [];

  if (growthbook?.evalFeature("subscription_age_tool").on) {
    forcerSectionList.push(
      <SubscriptionAgeDebugger key="SubscriptionAgeDebugger" />
    );
  }

  return forcerSectionList.concat(
    data.flatMap((section, i) => {
      const ageFilter =
        !!section?.filterByAge?.minAge || !!section?.filterByAge?.maxAge;
      let excludeByChildrenAges = false;
      if (ageFilter && !!userChildren) {
        excludeByChildrenAges = !userChildren.some((child) => {
          const childAge =
            new Date(
              Date.now() - new Date(child.birth_date).getTime()
            ).getUTCFullYear() - 1970;
          const minAgeValid =
            !section.filterByAge.minAge ||
            childAge >= section.filterByAge.minAge;
          const maxAgeValid =
            !section.filterByAge.maxAge ||
            childAge <= section.filterByAge.maxAge;
          return minAgeValid && maxAgeValid;
        });
      }
      if (
        !showAbTestComponent(
          section.abtesting,
          growthbook,
          subscriptionStartDate
        ) ||
        // section.hidden applies to Custom Carousel only (Legacy)
        // "Hide this carousel for users that are 30 days or older"
        (section.hidden && !userIsNew) ||
        !!excludeByChildrenAges
      )
        return [];

      activeSectionCount++;

      const sectionName = section.fieldGroupName?.split("FlexibleSections_")[1];

      switch (sectionName) {
        case "CustomCarousel":
          if (section.contentType == "latestFromTopic") {
            postData = section.latestFromTopic.postData[0];
          } else if (section.contentType == "customList") {
            postData = section.customList.chooseContent;
          }
          if (postData != null && postData.length) {
            const LibraryCarousel = dynamic(() =>
              import("@/components/LibraryCarousel")
            );
            return (
              <LibraryCarousel
                key={`${sectionName}-${i}-${section.title}`}
                heading={section.title}
                subheading={section.subtitle}
                postData={postData}
                placeholders={placeholders}
                fadeColor={carouselFadeColor}
                headingSize={activeSectionCount === 1 ? "h1" : "h2"}
                activeSectionCount={activeSectionCount}
              />
            );
          }
          break;
        case "PersonalizedCarousel":
          const PersonalizedCarousel = dynamic(() =>
            import("@/components/PersonalizedCarousel")
          );
          return (
            <PersonalizedCarousel
              key={`${sectionName}-${i}-${section.title}`}
              heading={section.title}
              subheading={section.subtitle}
              content={personalizedCarouselContent.sectionContent}
              placeholders={placeholders}
            />
          );
          break;
        case "WorkshopListing":
          if (section.contentType == "latestFromTopic") {
            postData = section.latestFromTopic.postData[0];
          } else if (section.contentType == "customList") {
            postData = section.customList.chooseContent;
          }

          if (postData != null && postData.length) {
            const WorkshopListings = dynamic(() =>
              import("@/components/WorkshopListings")
            );

            return (
              <WorkshopListings
                key={`${sectionName}-${i}-${section.title}`}
                heading={section.title}
                subheading={section.subtitle}
                postData={postData}
                placeholders={placeholders}
                showLink={section.viewAllLink}
                buttonDataId={section.buttonDataId}
              />
            );
          }
          break;

        case "TextSingleColumn":
          const SingleColumnText = dynamic(() =>
            import("@/components/SingleColumnText")
          );

          return (
            <SingleColumnText
              title={section.fieldGroupName}
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              desc={
                section.textSingleColumnContent
                  ? section.textSingleColumnContent
                  : null
              }
            />
          );
          break;
        case "PodcastCarousel":
          const PodcastCarousel = dynamic(() =>
            import("@/components/PodcastCarousel")
          );

          return (
            <PodcastCarousel
              title={section.testimonialsHeadline}
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              testimonial={podcastTestimonials}
            />
          );
          break;
        case "RecentPodcastEpisodes":
          const RecentPodcastEpisodes = dynamic(() =>
            import("@/components/RecentPodcastEpisodes")
          );
          return (
            <RecentPodcastEpisodes
              title={section.fieldGroupName}
              key={`${i}-${section.fieldGroupName}`}
              headline={section.headline}
              postData={recentPodcasts}
            />
          );
          break;
        case "ContactUsCallout":
          const ContactUsCallout = dynamic(() =>
            import("@/components/ContactUsCallout")
          );

          return (
            <ContactUsCallout
              title={section.headline}
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              description={section.description ? section.description : null}
              desktopImg={section.image}
              mobileImg={section.mobileImage}
              cta={section.cta}
            />
          );
          break;
        case "MembershipCallout":
          const LargeCallout = dynamic(() =>
            import("@/components/LargeCallout")
          );

          return (
            <LargeCallout
              title={section.calloutHeadline}
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              description={
                section.calloutDescription ? section.calloutDescription : null
              }
              pricePerMonth={
                section.pricePerMonth ? section.pricePerMonth : null
              }
              btnUrl={section.callToAction.url}
              btnTitle={section.callToAction.title}
              target={section.callToAction.target}
              buttonDataId={section.buttonDataId}
              imageSrc={
                section.backgroundImage
                  ? section.backgroundImage.sourceUrl
                  : null
              }
            />
          );
          break;
        case "PodcastHero":
          const PodcastHero = dynamic(() => import("@/components/PodcastHero"));

          return (
            <PodcastHero
              title={section.heroHeadline}
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              description={
                section.heroDescription ? section.heroDescription : null
              }
              appleUrl={
                section.applePodcastsLink ? section.applePodcastsLink : null
              }
              spotifyUrl={section.spotifyLink ? section.spotifyLink : null}
              imageSrc={section.heroImage ? section.heroImage.sourceUrl : null}
              mediaDetails={
                section.heroImage ? section.heroImage.mediaDetails : null
              }
              imgAltText={section.heroImage.title}
            />
          );
          break;
        case "PressReleases":
          const PressReleases = dynamic(() =>
            import("@/components/PressReleases")
          );

          return (
            <PressReleases
              key={`${sectionName}-${i}`}
              title={globalContent.pressReleases.headline}
              carouselItem={globalContent.pressReleases.pressItems}
            />
          );
          break;
        case "UpcomingEventsListing":
          const EventListings = dynamic(() =>
            import("@/components/EventListings")
          );

          return (
            <EventListings
              key={`${sectionName}-${i}-${globalContent.upcomingLiveEventsContent.title}`}
              preview={section.preview}
              heading={globalContent.upcomingLiveEventsContent.title}
              subheading={globalContent.upcomingLiveEventsContent.subtitle}
              link={globalContent.upcomingLiveEventsContent.link}
              ctaText={globalContent.upcomingLiveEventsContent.ctaButtonText}
              postData={liveEvents[0]}
              placeholders={placeholders}
            />
          );
          break;
        case "TopicTiles":
          const TopicTiles = dynamic(() => import("@/components/TopicTiles"));

          return (
            <TopicTiles
              key={`${sectionName}-${i}`}
              topics={topicCards}
              heading={section.heading}
              subheading={section.subheading}
            />
          );
          break;
        case "RecentlyViewedCarousel":
          const LibraryCarousel = dynamic(() =>
            import("@/components/LibraryCarousel")
          );

          return (
            <Fragment key={`${sectionName}-${i}-${section.heading}`}>
              {recentlyViewed && (
                <LibraryCarousel
                  key={`${sectionName}-${i}-${section.heading}`}
                  heading={section.heading}
                  subheading={section.subheading}
                  postData={recentlyViewed}
                  placeholders={placeholders}
                />
              )}
            </Fragment>
          );
          break;
        case "PageHero":
          const Hero = dynamic(() => import("@/components/Hero"));

          return (
            <Hero
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              title={section.heading}
              subheading={section.subHeading}
              featuredImage={featuredImage}
              mobileFeaturedImg={section.mobileFeaturedImg}
              featuredImageDisplay={section.featuredImageDisplay}
              illustrationImage={section.featureIllustration}
              eyebrow={section.eyebrow}
              reinforceText={section.reinforceText}
              mobileIllustration={section.mobileIllustration}
              bgColor={section.brandColor}
              showAccentCircle={section.showAccentCircle}
              mobileTextBoxBackground={section.mobileTextBoxBackground}
              link={section.link}
              buttonDataId={section.buttonDataId}
              sectionDataId={section.sectionDataId}
              headingSize={activeSectionCount === 1 ? "h1" : "h2"}
              activeSectionCount={activeSectionCount}
            />
          );
          break;
        case "ArchiveHero":
          const ArchiveHero = dynamic(() => import("@/components/ArchiveHero"));

          return (
            <Container key={`${sectionName}-${i}-${section.title}`}>
              <ArchiveHero
                title={section.title}
                desc={section.description ? section.description : null}
                bgColor={section.brandColor}
                imageSrc={
                  section.featureIllustration
                    ? section.featureIllustration.mediaItemUrl
                    : null
                }
                mediaDetails={
                  section.featureIllustration
                    ? section.featureIllustration.mediaDetails
                    : null
                }
              />
            </Container>
          );

          break;
        case "Showreel":
          const Showreel = dynamic(() => import("@/components/Showreel"));

          return (
            <Showreel
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              content={showreel}
              cards={cards}
              placeholders={placeholders}
              footnote={section.footnote}
            />
          );
          break;
        case "Featuring":
          const Featuring = dynamic(() => import("@/components/Featuring"));

          return (
            <div key={`${sectionName}-${i}-${section.caption}`}>
              <Featuring
                caption={section.caption}
                author={section.author}
                byline={section.byline}
                image={section.image}
                logos={section.logos}
                headingSize={sectionCount == 1 ? "h1" : "h2"}
              />
            </div>
          );
          break;
        case "ThumbnailCards":
          const ThumbnailCards = dynamic(() =>
            import("@/components/ThumbnailCards")
          );

          return (
            <ThumbnailCards
              key={`${sectionName}-${i}-${section.thumbnailCardsTitle}`}
              title={section.thumbnailCardsTitle}
              cards={section.thumbnailCardsCards}
              footnote={section.footnote}
              link={section.link}
              buttonDataId={section.buttonDataId}
              headingSize={sectionCount == 1 ? "h1" : "h2"}
            />
          );
          break;
        case "Plans":
          const Plans = dynamic(() => import("@/components/Plans"));

          return (
            <Plans
              title={section.title}
              text={section.text}
              plans={plans}
              key={`${sectionName}-${i}-${section.title}`}
            />
          );
          break;
        case "Testimonials":
          const Testimonials = dynamic(() =>
            import("@/components/Testimonials")
          );

          return (
            <Testimonials
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              title={section.title}
              testimonials={testimonials}
              headingSize={sectionCount == 1 ? "h1" : "h2"}
            />
          );
          break;
        case "Faqs":
          const FAQ = dynamic(() => import("@/components/FAQ"));

          return (
            <FAQ
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              title={section.title}
              showMembershipCta={section.showMembershipCta}
              faqs={faqs}
              footnote={section.footnote}
              buttonDataId={section.buttonDataId}
              headingSize={sectionCount == 1 ? "h1" : "h2"}
            />
          );
          break;
        case "Callout":
          const Callout = dynamic(() => import("@/components/Callout"));
          if (section.hideFromUsers & !!user) {
            return null;
          }
          return (
            <Callout
              image={section.image}
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              subheading={section.subheading}
              heading={section.heading}
              backgroundColor={section.backgroundColor}
              foregroundColor={section.foregroundColor}
              topPadding={section.topPadding}
              bottomPadding={section.bottomPadding}
              headingSize={sectionCount == 1 ? "h1" : "h2"}
            />
          );
          break;
        case "CheckoutCallout":
          const CheckoutCallout = dynamic(() =>
            import("@/components/CheckoutCallout")
          );

          return (
            <CheckoutCallout
              image={section.image}
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              subheading={section.subheading}
              heading={section.heading}
              backgroundColor={section.backgroundColor}
            />
          );
          break;
        case "PurchasedWorkshops":
          const PurchasedWorkshops = dynamic(() =>
            import("@/components/PurchasedWorkshops")
          );
          return (
            <PurchasedWorkshops
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              placeholders={placeholders}
              heading={section.heading}
              subheading={section.subheading}
              displayViewAll={section.viewAllLink}
            />
          );
          break;
        case "BookHero":
          const PromoHero = dynamic(() => import("@/components/PromoHero"));
          return (
            <PromoHero
              key={`${i}-${section.fieldGroupName}`}
              title={section.title}
              description={section.description}
              featuredImage={section.featureIllustration}
              btnText={section.buttonText}
              bgImage={section.backgroundImage}
              badge={section.badge}
              badgeDate={section.badgeDate}
            />
          );
          break;
        case "SingleTestimonial":
          const SingleTestimonial = dynamic(() =>
            import("@/components/SingleTestimonial")
          );
          return (
            <SingleTestimonial
              key={`${i}-${section.fieldGroupName}`}
              text={section.testimonialText}
              name={section.testimonialName}
              testimonialRef={section.testimonialRef}
              imgSrc={section.testimonialImage}
              bgColor={section.backgroundColor}
              accent={section.accentColor}
            />
          );
          break;
        case "TwoColumnArticle":
          const TwoColumnArticle = dynamic(() =>
            import("@/components/TwoColumnArticle")
          );
          return (
            <TwoColumnArticle
              key={`${i}-${section.fieldGroupName}`}
              intro={section.introText}
              text={section.article}
              imgSrc={section.featureImage}
            />
          );
          break;
        case "SectionAbout":
          const SectionAbout = dynamic(() =>
            import("@/components/SectionAbout")
          );
          return (
            <SectionAbout
              key={`${i}-${section.fieldGroupName}-${section.title}`}
              title={section.title}
              intro={section.introText}
              text={section.text}
              social={section.socialTitle}
              imgSrc={section.featureImage}
              links={section.socialLinks}
              bgColor={section.backgroundColor}
            />
          );
          break;
        case "InfoCards":
          const InfoCards = dynamic(() => import("@/components/InfoCards"));
          return (
            <InfoCards
              key={`${i}-${section.fieldGroupName}`}
              cards={section.infoCards}
              book={book}
            />
          );
          break;
        case "PromoCta":
          const PromoCta = dynamic(() => import("@/components/PromoCta"));
          return (
            <PromoCta
              key={`${i}-${section.fieldGroupName}-${section.heading}`}
              heading={section.heading}
              headline={section.headline}
              element={section.ctaLinks}
              logo={section.logo}
              subheading={section.subHeading}
              text={section.text}
              singleLink={section.singleLink}
              footerTitle={section.footerTitle}
              footerText={section.footerText}
              bgColor={section.backgroundColor}
              footerBg={section.footerBackground}
            />
          );
          break;
        case "Reviews":
          const Reviews = dynamic(() => import("@/components/Reviews"));
          return (
            <Reviews
              key={`${i}-${section.fieldGroupName}-${section.title}`}
              image={section.featureImage}
              title={section.title}
              reviewlist={section.reviews}
            />
          );
          break;
        case "AboutUsHero":
          const AboutHero = dynamic(() => import("@/components/AboutHero"));
          return (
            <AboutHero
              key={`${i}-${section.fieldGroupName}-${section.heading}`}
              title={section.heading}
              subheading={section.subHeading}
              description={section.description}
              featuredImage={featuredImage}
              illustrationImage={section.featureIllustration}
              illustrationBadge={section.featureIllustrationBadge}
            />
          );
        case "AboutUsFeature":
          const AboutFeature = dynamic(() =>
            import("@/components/AboutFeature")
          );

          return (
            <AboutFeature
              key={`${i}-${section.fieldGroupName}-${section.heading}`}
              title={section.heading}
              subheading={section.subHeading}
              description={section.description}
              illustrationImage={section.featuredIllustration}
            />
          );
        case "AboutUsCards":
          const AboutCards = dynamic(() => import("@/components/AboutCards"));

          return <AboutCards title={section.heading} cards={section.cards} />;
          break;
        case "MarketingBanner":
          const MarketingBanner = dynamic(() =>
            import("@/components/MarketingBanner")
          );
          return (
            <MarketingBanner
              key={`${i}-${section.fieldGroupName}-marketing-banner`}
              featureImage={
                section.override
                  ? section.featureImage
                  : globalContent?.marketingBanner?.featureImage
              }
              headline={
                section.override
                  ? section.headline
                  : globalContent?.marketingBanner?.headline
              }
              text={
                section.override
                  ? section.text
                  : globalContent?.marketingBanner?.text
              }
              cta={
                section.override
                  ? section.cta
                  : globalContent?.marketingBanner?.cta
              }
            />
          );
          break;
        case "CircleCarousel":
          const CircleCarousel = dynamic(() =>
            import("@/components/CircleCarousel")
          );
          return (
            <Fragment key={`${sectionName}-${i}-${section.fieldGroupName}`}>
              {!!circlePostData && (
                <CircleCarousel
                  key={`${sectionName}-${i}-${section.fieldGroupName}`}
                  heading={section.heading}
                  link={section.link}
                  circlePostData={circlePostData}
                />
              )}
            </Fragment>
          );
          break;
        case "PersonalizedCircleCarousel":
          const PersonalizedCircleCarousel = dynamic(() =>
            import("@/components/CircleCarousel/PersonalizedCircleCarousel")
          );
          return (
            <Fragment key={`${sectionName}-${i}-${section.fieldGroupName}`}>
              {!!personalizedCirclePostData && (
                <PersonalizedCircleCarousel
                  key={`${sectionName}-${i}-${section.fieldGroupName}`}
                  heading={section.heading}
                  linkTitle={section.linkTitle}
                  content={section.topicSearchTerms}
                  circlePostData={personalizedCirclePostData}
                />
              )}
            </Fragment>
          );
          break;
        case "PreOrderBook":
          const PreorderBook = dynamic(() =>
            import("@/components/PreorderBook")
          );
          return (
            <PreorderBook
              logo={section.logo}
              heading={section.heading}
              subheading={section.subheading}
              features={section.features}
              stepOneTitle={section.step1Title}
              availabilityDate={section.availabilityDate}
              links={section.links}
              stepTwoTitle={section.step2Title}
              thankYou={section.thankYou}
              footerTitle={section.footerTitle}
              footerText={section.footerText}
              bgColor={section.backgroundColor}
              footerBg={section.footerBackground}
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
            />
          );
          break;
        case "SupportCallout":
          const SupportCallout = dynamic(() =>
            import("@/components/SupportCallout")
          );
          return (
            <SupportCallout
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              description={section.description}
              link={section.link}
              image={section.image}
              color={section.backgroundColor}
            />
          );
          break;
        case "StampedReview":
          const StampedReview = dynamic(() =>
            import("@/components/StampedReview")
          );
          return (
            <StampedReview
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              title={section.title}
              subtitle={section.subtitle}
              reviewsPerPage={section.reviewsPerPage}
              sectionType={section.sectionType}
              productId={section.productId}
              reviewsHeading={section.reviewsHeading}
              reviewsImage={section.reviewsImage}
              featuredImage={featuredImage}
            />
          );
          break;
        case "PressReviews":
          const PressReviews = dynamic(() =>
            import("@/components/PressReleases")
          );
          return (
            <PressReviews
              key={`${sectionName}-${i}`}
              title={section.headline}
              carouselItem={section.pressItems}
              review={section.fieldGroupName}
            />
          );
          break;
        case "WeeklyFlex":
          const SmsForm = dynamic(() => import("@/components/SmsForm"));
          return (
            <section
              key={`${sectionName}-${i}`}
              style={{ backgroundColor: section.backgroundColor }}
            >
              <Container
                className={cn({
                  "max-w-lg":
                    !section.componentWidth ||
                    section.componentWidth == "small",
                  "max-w-xl": section.componetWidth == "medium",
                  "max-w-2xl": section.componetWidth == "large",
                })}
              >
                <SmsForm
                  icon={section.icon}
                  aboveFormContent={section.aboveFormContent}
                  belowFormContent={section.belowFormContent}
                  successText={section.successText}
                  comingSoonText={section.comingSoonText}
                  ctaText={section.ctaText}
                  componentWidth={section.componentWidth}
                />
              </Container>
            </section>
          );
          break;
        case "SeasonalCallout":
          const SeasonalCallout = dynamic(() =>
            import("@/components/SeasonalCallout")
          );
          return (
            <SeasonalCallout
              key={`${sectionName}-${i}`}
              callout={section?.seasonalCallout}
              banner={section?.charityBanner}
              enableBanner={section.enableBanner}
              headingSize={activeSectionCount === 1 ? "h1" : "h2"}
            />
          );
          break;
        case "EditorialCarousel":
          const EditorialCarousel = dynamic(() =>
            import("@/components/EditorialCarousel")
          );
          return (
            <EditorialCarousel
              key={`${sectionName}-${i}`}
              headline={section.headline}
              subHeadline={section.subHeadline}
              cta={section.cta}
              ctaCard={section.ctaCard}
              postData={section.chooseContent}
              headingSize={activeSectionCount === 1 ? "h1" : "h2"}
            />
          );
          break;
        case "PersonalizedWorkshop":
          const WorkshopListings = dynamic(() =>
            import("@/components/WorkshopListings")
          );
          return (
            <Fragment key={`${sectionName}-${i}`}>
              {user?.topic ? (
                <WorkshopListings
                  key={`${sectionName}-${i}`}
                  heading={section.heading}
                  subheading={section.subheading}
                  postData={section.choices
                    ?.filter((item) => item.choice.name == user?.topic)
                    .map((item) => item.featuredWorkshop)}
                  placeholders={placeholders}
                />
              ) : (
                []
              )}
            </Fragment>
          );
          break;
        case "TolstoyEmbed":
          const TolstoyEmbed = dynamic(() =>
            import("@/components/TolstoyEmbed")
          );
          return (
            <TolstoyEmbed
              key={`${sectionName}-${i}`}
              widgetId={section.widgetId}
              delayInterval={section.delayStart}
              alwaysShow={section.alwaysShow}
              disallowedUrls={section.disallowedUrls}
            />
          );
          break;
        case "ImageBanner":
          const ImageBanner = dynamic(() => import("@/components/ImageBanner"));
          if (section.hideFromUsers & !!user) {
            return null;
          }
          return (
            <ImageBanner
              key={`${sectionName}-${i}`}
              mobileImage={section.mobileImage}
              desktopImage={section.desktopImage}
              bgColor={section.bgColor}
              heading={section.heading}
              ctaText={section.ctaText}
              ctaLink={section.ctaLink}
            />
          );
          break;
        case "SmallHero":
          const SmallHero = dynamic(() => import("@/components/SmallHero"));

          return (
            <SmallHero
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              title={section.heading}
              subheading={section.subHeading}
              featuredImage={featuredImage}
              mobileFeaturedImg={section.mobileFeaturedImg}
              brandColor={section.brandColor}
              mobileTextBoxBackground={section.mobileTextBoxBackground}
              mobileTextBoxOffset={section.mobileTextBoxOffset}
              showAccentCircle={section.showAccentCircle}
              ctaText={section.ctaText}
              userCtaText={section.userCtaText}
              ctaLink={section.ctaLink}
              buttonDataId={section.buttonDataId}
              sectionDataId={section.sectionDataId}
            />
          );
          break;
        case "Spacer":
          const Spacer = dynamic(() => import("@/components/Spacer"));
          return (
            <Spacer
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              backgroundColor={section.backgroundColor}
              override={section.customOverride}
              preset={section.presetSpacer}
              custom={section.customSpacer}
            />
          );
          break;
        case "AcquisitionQuiz":
          const AcquisitionQuiz = dynamic(
            () => import("@/components/AcquisitionQuiz"),
            { ssr: false }
          );
          return (
            <AcquisitionQuiz
              key={`${sectionName}-${i}-${section.fieldGroupName}`}
              id={section.quizId}
            />
          );
          break;
      }
    })
  );
}

export default memo(FlexibleSections);
