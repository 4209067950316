import type {
  AbTestFields,
  TimeGatingConfiguration,
} from "../fragments/graphQLqueries/abTestQuery";
import { SectionVisibility } from "../fragments/graphQLqueries/abTestQuery";
import type { GrowthBook } from "@growthbook/growthbook-react";
import differenceInDays from "date-fns/differenceInDays";

export const calculateVisibility = (
  visibility: SectionVisibility,
  daysAfterSubscription: number,
  timeGatingConfiguration: TimeGatingConfiguration | null
): boolean => {
  if (visibility !== SectionVisibility.TimeGated || !timeGatingConfiguration) {
    return visibility === SectionVisibility.Show;
  }
  return (
    timeGatingConfiguration.startDay <= daysAfterSubscription &&
    (!timeGatingConfiguration.endDay ||
      timeGatingConfiguration.endDay >= daysAfterSubscription)
  );
};

const showAbTestComponent = (
  abTestComponentData: AbTestFields,
  growthbook: GrowthBook,
  userSubscriptionAge: Date | null
): boolean => {
  if (
    !growthbook ||
    abTestComponentData === undefined ||
    !abTestComponentData.control
  )
    return true;

  const daysAfterSubscription = userSubscriptionAge
    ? differenceInDays(new Date(), userSubscriptionAge)
    : 0;

  let showSection = calculateVisibility(
    abTestComponentData.control.visibility,
    daysAfterSubscription,
    abTestComponentData.control.timeGatingConfiguration
  );

  if (!abTestComponentData.experiments) {
    return showSection;
  }

  for (let experiment of abTestComponentData.experiments) {
    if (
      growthbook.feature(experiment.experiment).value === experiment.variant
    ) {
      showSection = calculateVisibility(
        experiment.visibility,
        daysAfterSubscription,
        experiment.timeGatingConfiguration
      );
    }
  }

  return showSection;
};

export default showAbTestComponent;
